//This is Virtual Consultation page
import React, { useState, useEffect } from "react";
// import "../VirtualConsultation/VirtualConsultation.css";
import { db, storage } from "../../firebase";
import {
  getDocs,
  collection,
  setDoc,
  where,
  query,
  doc,
} from "firebase/firestore";
import { listAll, ref, getDownloadURL } from "firebase/storage";
import Loading from "../../components/Loading";
import { useSelector } from "react-redux";
import { user } from "../../redux/reducers/userReducer";
import { uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import { useStateContext } from "../../contexts/ContextProvider";

const UploadPicture = () => {
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState("");
  const [imgUrl, setImgUrl] = useState([]);
  const { activeMenu } = useStateContext();

  const handleClick = () => {
    
    if (img !== "") {
      setLoading(true);
      const imgRef = ref(storage, `bloom_files/${v4()}`);
      uploadBytes(imgRef, img).then((value) => {
        console.log(value);
        getDownloadURL(value.ref).then((url) => {
          setImgUrl((data) => [...data, url]);
        });
        setLoading(false);
      });
    }
    else{
      alert('Please choose an image first!!!')
    }
  };

  useEffect(() => {
    listAll(ref(storage, "bloom_files")).then((imgs) => {
      console.log(imgs);
      imgs.items.forEach((val) => {
        getDownloadURL(val).then((url) => {
          setImgUrl((data) => [...data, url]);
        });
      });
    });
  }, []);

  return (
    <div>
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div className="bg-[#f5f5f5] pb-12 pt-5 max-sm:pt-[80px]">
          <div style={{ padding: "0px 50px" }}>
            <span className="font-semibold">Please follow the below instructions</span>
            <p className="text-sm font-medium">
              Please upload your picture here and track your weightloss progress
              <br /> 1. Click on the Choose File button
              <br />
              2. Select your picture
              <br />
              3. Click on Upload button
              <br />
              4. Track your progress
            </p>
          </div>

          <div style={{ padding: "10px 50px" }}>
            <input type="file" onChange={(e) => setImg(e.target.files[0])} />
            <button
              className="bg-button-bg text-white text-center py-2 px-4 rounded-sm font-medium text-sm hover:cursor-pointer hover:shadow-lg"
              onClick={handleClick}
            >
              Upload
            </button>

          </div>

          <br />
          <div
            className="grid xl:grid-cols-4 md:grid-cols-2 gap-4 mr-4 ml-4"
          >
          {imgUrl.map((dataVal) => (
          
            <div
            key={dataVal.id}
                // key={item.id}
                className="bg-white rounded-sm shadow-sm p-4 flex flex-col gap-2"
              >
                <img
                  // src={item.image}
                  // alt={item.title}
                  src={dataVal}
                  className="h-[230px] object-cover"
                />
                <span className="font-semibold capitalize text-sm md:text-base text-center">
                  Week
                </span>
                
              </div>
          ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadPicture;
































// import React, { useState, useEffect } from "react";
// // import "../VirtualConsultation/VirtualConsultation.css";
// import { db, storage } from "../../firebase";
// import {
//   getDocs,
//   collection,
//   setDoc,
//   updateDoc,
//   where,
//   query,
//   doc,
// } from "firebase/firestore";
// import { listAll, ref, getDownloadURL } from "firebase/storage";
// import Loading from "../../components/Loading";
// import { useSelector } from "react-redux";
// import { user } from "../../redux/reducers/userReducer";
// import { uploadBytes } from "firebase/storage";
// import { v4 } from "uuid";
// import { useStateContext } from "../../contexts/ContextProvider";

// const UploadPicture = () => {
//   const [loading, setLoading] = useState(false);
//   const [img, setImg] = useState("");
//   const [imgUrl, setImgUrl] = useState([]);
//   const { activeMenu } = useStateContext();
//   const currentuser = useSelector(user);
//   const [imageData, setImageData] = useState([]);

//   const docRef = doc(
//     db,
//     "BloomDB/BloomCustomerImage/WeightLossImage",
//     currentuser.email
//   );

//   const handleClick = async () => {
//     try {
//       if (img !== "") {
//         setLoading(true);
//         const imgRef = ref(storage, `bloom_files/${v4()}`);
//         uploadBytes(imgRef, img).then((value) => {
//           // console.log(value);
//           getDownloadURL(value.ref).then((url) => {
//             setImgUrl((data) => [...data, url]);

//             console.log("%%%%%%%%%", imgUrl);
//           });
//           // setDoc(
//           //   doc(
//           //     db,
//           //     "BloomDB/BloomCustomerImage/WeightLossImage",
//           //     currentuser.email
//           //   ),
//           //   {
//           //     email: currentuser.email,
//           //     title: "Week " + 1,
//           //     image: imgUrl,

//           //   }
//           // );
//           // doc(
//           //   db,
//           //   "BloomDB/BloomCustomerImage/WeightLossImage",
//           //   currentuser.email
//           // )

//           updateDoc(
//             doc(
//               db,
//               "BloomDB/BloomCustomerImage/WeightLossImage",
//               currentuser.email
//             ),
//             {
//               image: {
//                 week: imgUrl,
//               },
//             }
//           ).then(() => {
//             console.log("Image uploaded successfully!!");
//             alert("Image uploaded successfully!!");
//             setLoading(false);
//           });

//           //Update function
//           // db.doc("BloomDB/BloomCustomerImage/WeightLossImage").doc(currentuser.email).update({
//           //   image: {
//           //     week1: imgUrl,
//           //   }
//           // }).then(()=> {
//           //   console.log("Image uploaded successfully!!");
//           //   alert("Image uploaded successfully!!");
//           //   setLoading(false);
//           // });
//           // alert("Image uploaded successfully!!");
//           // getImage();
//           // setLoading(false);
//         });
//       } else {
//         alert("Please choose an image first!!!");
//       }
//     } catch {
//       alert("something went wrong, please try again!!");
//     }
//   };

//   // useEffect(() => {
//   //   listAll(ref(storage, "bloom_files")).then((imgs) => {
//   //     console.log(imgs);
//   //     imgs.items.forEach((val) => {
//   //       getDownloadURL(val).then((url) => {
//   //         setImgUrl((data) => [...data, url]);
//   //       });
//   //     });
//   //   });
//   // }, []);

//   const getImage = async () => {
//     try {
//       setLoading(true);
//       const instructionRef = collection(
//         db,
//         "BloomDB/BloomCustomerImage/WeightLossImage"
//       );
//       await getDocs(instructionRef).then((response) => {
//         const treatments = response.docs.map((doc) => ({
//           ...doc.data(),
//         }));
//         console.log("Data: ", treatments);
//         setImageData(treatments);
//         setLoading(false);
//       });
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   useEffect(() => {
//     getImage();
//   }, []);

//   return (
//     <div>
//       {loading ? (
//         <div>
//           <Loading />
//         </div>
//       ) : (
//         <div className="bg-[#f5f5f5] pb-12 pt-5 max-sm:pt-[80px]">
//           <div style={{ padding: "0px 50px" }}>
//             <span className="font-semibold">
//               Please follow the below instructions
//             </span>
//             <p className="text-sm font-medium">
//               Please upload your picture here and track your weightloss progress
//               <br /> 1. Click on the Choose File button
//               <br />
//               2. Select your picture
//               <br />
//               3. Click on Upload button
//               <br />
//               4. Track your progress
//             </p>
//           </div>

//           <div style={{ padding: "10px 50px" }}>
//             <input type="file" onChange={(e) => setImg(e.target.files[0])} />
//             <button
//               className="bg-button-bg text-white text-center py-2 px-4 rounded-sm font-medium text-sm hover:cursor-pointer hover:shadow-lg"
//               onClick={handleClick}
//             >
//               Upload
//             </button>
//           </div>

//           <br />
//           <div className="grid xl:grid-cols-4 md:grid-cols-2 gap-4 mr-4 ml-4">
//             {imageData !== null
//               ? imageData.map((dataVal) => (
//                   <div
//                     key={dataVal.email}
//                     // key={item.id}
//                     className="bg-white rounded-sm shadow-sm p-4 flex flex-col gap-2"
//                   >
//                     {/* {dataVal.image !== null ? dataVal.image.map((item) => (
//                  <img
//                  // src={item.image}
//                  // alt={item.title}
//                  src={item}
//                  className="h-[230px] object-cover"
//                />
//                )): null} */}

//                     <span className="font-semibold capitalize text-sm md:text-base text-center">
//                       {dataVal.title}
//                     </span>
//                   </div>
//                 ))
//               : null}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default UploadPicture;
