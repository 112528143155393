import React, { useState } from "react";
import OTPInput from "otp-input-react";
import "./Authentication.css";
import { CircularProgress } from "@mui/material";

const Verification = ({
  setNewUser,
  setIsVerificationRequired,
  sendUserData,
}) => {
  const [otp, setOtp] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const inputStyle = {
    padding: "10px",
    boxShadow: "none",
    border: "1px solid #116C6E",
  };

  const verifyOTP = () => {
    setLoading(true);
    setError("");
    setSuccess("");
    window.confirmationResult
      .confirm(otp)
      .then(async (response) => {
        console.log("otp matches");
        setLoading(false);
        setSuccess("Your Phone number is successfully verified");
        sendUserData();
        setTimeout(() => {
          setNewUser(false);
          setIsVerificationRequired(false);
        }, 2000);
        // create the document if the otp matches
      })
      .catch((err) => {
        setLoading(false);
        console.log("otp does not match");
        setError("Please Enter Valid OTP");
        console.log(err);
      });
  };

  return (
    <div className="flex flex-col items-center  justify-center gap-6 text-gray-700">
      <div className="flex justify-center items-center p-2 mt-4">
        <img className="h-24" src="../assets/bloomlogo.png" alt="Bitcare" />
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4 mt-4">
          <p className="text-center text-lg uppercase font-semibold">
            Enter your code
          </p>
          <p className="text-center text-sm font-medium">
            Please enter the code we sent to your phone
          </p>
        </div>
        <div>
          <OTPInput
            OTPLength={6}
            autoFocus
            diabled={false}
            otpType="number"
            onChange={setOtp}
            value={otp}
            className="flex items-center justify-center ml-2"
            inputStyles={inputStyle}
          />
        </div>
        {error && (
          <span className="text-xs text-red-700 font-semibold text-center">
            Please Enter Valid OTP
          </span>
        )}
        {success && (
          <span className="text-xs text-green-700 font-semibold text-center">
            Your Phone number is successfully verified
          </span>
        )}
        <div>
          <p
            onClick={verifyOTP}
            className="bg-button-bg px-4 py-2 text-white rounded-sm hover:shadow-md cursor-pointer flex items-center gap-4 justify-center"
          >
            {loading && <CircularProgress color="inherit" size={20} />}
            Verify
          </p>
        </div>
      </div>
    </div>
  );
};

export default Verification;

/**
 * 
**Documentation: Verification Component

The Verification component is responsible for rendering the OTP verification screen
 where users can enter the OTP (One-Time Password) sent to their phone number for verification during the registration process.

**Props:
setNewUser: A function passed from the parent component to control the state of whether a new user is being registered.
setIsVerificationRequired: A function passed from the parent component to control the state of whether OTP verification is required.
sendUserData: A function passed from the parent component to handle the submission of user data after successful OTP verification.


**State:
otp: A state variable that holds the OTP entered by the user.
success: A state variable that holds a success message when the OTP is successfully verified.
error: A state variable that holds an error message when the OTP entered is invalid.
loading: A state variable to manage the loading state when verifying the OTP.

**Functions:
verifyOTP: A function that is called when the user clicks the "Verify" button. 
It attempts to verify the OTP entered by the user against the OTP sent to the user's phone number. 
If the OTP matches, it sets the success message, sends the user data, and switches the view back to the login screen after a brief delay. 
If the OTP does not match, it sets an error message to inform the user.

**Component Structure:
1. The component renders a form with an input field for entering the OTP and a "Verify" button.
2.The OTP input field is implemented using the OTPInput component, which allows the user to enter a 6-digit OTP.
3. When the "Verify" button is clicked, the verifyOTP function is called to validate the OTP.
4.If the OTP is valid, a success message is displayed below the OTP input field, and the sendUserData function is called to send the user's data to the backend.
5. After a brief delay (2 seconds), the view is switched back to the login screen by setting the setNewUser and setIsVerificationRequired states to false.
6. If the OTP is invalid, an error message is displayed below the OTP input field.

**Usage:
The Verification component is used as part of the registration process to verify the user's phone number using an OTP.
After a user successfully registers and provides their phone number, an OTP is sent to their phone, 
and the Verification component is rendered to allow the user to enter the OTP for verification.

The component handles OTP verification, displays success and error messages accordingly, 
and switches the view back to the login screen after successful verification.
 */
