import React, { useState } from "react";

function FAQSection() {
  // Define FAQ items as an array of objects
  const faqItems = [
    {
      question: "What is Testosterone Replacement Therapy?",
      answer: "TRT is a medical treatment designed to boost low testosterone levels in men experiencing symptoms of testosterone deficiency. It works by supplementing the body with testosterone through various delivery methods, helping to restore normal levels and alleviate symptoms.",
    },
    {
      question: "Who is a good candidate for TRT?",
      answer:
        "Good candidates for TRT are men with clinically low testosterone levels confirmed by blood tests, who also exhibit symptoms such as fatigue, decreased libido, muscle weakness, and mood changes.",
    },
    {
      question: "What symptoms of low testosterone does TRT address?",
      answer:
        "TRT addresses a range of symptoms associated with low testosterone, including but not limited to, low sex drive, erectile dysfunction, reduced muscle mass, fatigue, and mood swings.",
    },
    {
      question: "Are there any side effects associated with TRT?",
      answer:
        "Yes, TRT can have side effects, including acne, sleep apnea, blood clotting, and changes in cholesterol levels. It’s important to discuss these potential risks with a healthcare provider.",
    },
    {
      question: "How long does it take to see results from TRT?",
      answer:
        "Results from TRT can vary, but many men start to notice improvements in symptoms like energy levels and libido within 3 to 6 weeks, with full benefits often realized within 3 to 6 months.",
    },
    {
      question: "Is TRT safe for long-term use?",
      answer:
        "TRT can be safe for long-term use under the supervision of a healthcare provider, with regular monitoring to adjust dosage as needed and to check for any adverse effects.",
    },
    {
      question: "How is TRT administered, and what options are available?",
      answer:
        "TRT can be administered through injections, topical gels, patches, or subcutaneous pellets. The best option depends on individual preferences, lifestyle, and response to treatment.",
    },
    {
      question: "Will I need to continue TRT indefinitely?",
      answer:
        "The need for ongoing TRT depends on the underlying cause of low testosterone. Some men may require long-term treatment, while others might use it temporarily. Regular follow-up with a healthcare provider is essential.",
    },
    {
      question: "What is the process for starting TRT with Bloom, and what kind of support can I expect?",
      answer:
        "Starting TRT with Bloom involves an initial consultation, comprehensive blood tests to confirm low testosterone levels, and a personalized treatment plan. Patients can expect ongoing support, including regular follow-ups to monitor progress and adjust treatment as necessary.",
    },
    // Add more FAQ items as needed
  ];

  // State to track which FAQ item is currently open
  const [activeIndex, setActiveIndex] = useState(null);

  // Function to toggle the active index
  const toggleItem = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="mt-20 md:mt-10 mx-3">
      <div className="faq-section max-w-md mx-auto">
      <h2 className="text-2xl font-semibold mb-4">Frequently Asked Questions</h2>
      <div className="faq-list flex flex-wrap">
        {faqItems.map((item, index) => (
          <div key={index} className="faq-item w-full">
            <div
              className={`flex items-center justify-between question cursor-pointer w-full ${activeIndex === index ? 'text-orange-500' : 'text-blue-500'}`}
              onClick={() => toggleItem(index)}
            >
              <span className="text-xl font-bold w-10/12">{item.question}</span>
              <svg
                className={`h-6 w-6 transition-transform transform ${activeIndex === index ? 'rotate-180' : ''}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            {activeIndex === index && (
              <div className="answer mt-2">
                {item.answer}
              </div>
            )}
            <div className="border-b border-gray-300"></div>
          </div>
        ))}
      </div>
    </div>
  </div>
  );
}

export default FAQSection;