import { AiOutlineCalendar, AiOutlineGift } from "react-icons/ai";
import { BsReceipt } from "react-icons/bs";
import { BiCalculator, BiPackage } from "react-icons/bi";
import { RiMoneyDollarBoxLine, RiDashboardLine } from "react-icons/ri";
import {
  MdCardMembership,
  MdOutlineIntegrationInstructions,
  MdOutlineMedicalServices,
  MdMessage,
  MdQuestionAnswer,
  MdPictureInPicture,
  MdImageSearch,
  MdOutlineImage,
  MdUploadFile,
  MdOutlineQuestionAnswer,
} from "react-icons/md";

export const links = [
  {
    id: "dashboard",
    links: [
      {
        name: "Dashboard",
        icon: <RiDashboardLine />,
        link: "/",
      },
    ],
  },
  {
    id: "appointments",
    title: "Appointments",
    links: [
      {
        name: "Book an Appointment",
        icon: <AiOutlineCalendar />,
        link: "/booking",
      },
      // {
      //   name: "Virtual Consultation",
      //   icon: <AiOutlineCalendar />,
      //   link: "/virtualconsultation",
      // },
    ],
  },
  // {
  //   id: "medical",
  //   title: "Medical",
  //   links: [
  //     {
  //       name: "Medical History",
  //       icon: <BsReceipt />,
  //       link: "healthhistory",
  //     },
  //   ],
  // },
  // {
  //   id: "ourservices",
  //   title: "Our Services",
  //   links: [
  //     {
  //       name: "Services",
  //       icon: <MdOutlineMedicalServices />,
  //       link: "medspaservices",
  //     },
  //   ],
  // },
  {
    id: "features",
    title: "Features",
    links: [
      // {
      //   name: "Messaging",
      //   icon: <MdMessage />,
      //   link: "/",
      // },
      {
        name: "Education",
        icon: <MdOutlineIntegrationInstructions />,
        link: "education",
      },
      {
        name: "Track Progress",
        icon: <MdOutlineImage/>,
        link: "/UploadPicture",
      },
      {
        name: "Medication Management",
        icon: <MdOutlineMedicalServices />,
        link: "/",
      },
      {
        name: "FAQs",
        icon: <MdOutlineQuestionAnswer/>,
        link: "/FAQSection",
      },
      // {
      //   name: "Membership",
      //   icon: <MdCardMembership />,
      //   link: "membership",
      // },
      // {
      //   name: "Packages",
      //   icon: <BiPackage />,
      //   link: "packages",
      // },
      // {
      //   name: "Referrals",
      //   icon: <AiOutlineGift />,
      //   link: "referral",
      // },
      // {
      //   name: "Rewards",
      //   icon: <RiMoneyDollarBoxLine />,
      //   link: "rewards",
      // },
      // {
      //   name: "Pre and Post Instructions",
      //   icon: <MdOutlineIntegrationInstructions />,
      //   link: "education",
      // },
      // {
      //   name: "BMI Calculator",
      //   icon: <BiCalculator />,
      //   link: "bmicalculator",
      // },
    ],
  },
];
