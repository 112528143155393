import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { useDispatch } from "react-redux";
import { SetEmail } from "../../redux/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import Forgotpassword from "./Forgotpassword";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Cookies from "js-cookie";
import { Tooltip } from "@mui/material";

const Login = ({ setNewUser }) => {
  const initialValues = { email: "", password: "" };
  const [formvalues, setFormvalues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isForgot, setIsForgot] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormvalues({ ...formvalues, [name]: value });
  };

  // check first the token is created or not
  useEffect(() => {
    let loggedIn = Cookies.get("loggedIn");
    if (loggedIn === "true") {
      navigate("/");
    }
  }, []);

  //function to validate the whethter the user is registered with our app or not
  const login = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(
        auth,
        formvalues.email,
        formvalues.password
      ).then((response) => {
        const userEmail = response.user.email;
        if (userEmail) {
          dispatch(SetEmail(userEmail));
        }
        Cookies.set("loggedIn", "true", { expires: 7 }); //setting cookies which expires after 7 days
        navigate("/");
      });
    } catch (err) {
      console.log(err.code);
      setErrors(validate(err.code));
    }
  };

  //Function to validate whether email is in correct format or not
  const validate = (error) => {
    const errors = {};
    if (error === "auth/invalid-email") {
      errors.email = "Email is required!";
    }

    if (error === "auth/internal-error") {
      errors.password = "Please enter the password";
    }
    if (error === "auth/user-not-found") {
      errors.email = "Please enter valid email address";
    }
    if (error === "auth/wrong-password") {
      errors.password = "Please enter correct password";
    }
    return errors;
  };

  return (
    <>
      {isForgot ? (
        <Forgotpassword setIsForgot={setIsForgot} />
      ) : (
        <div className="flex flex-col text-gray-700 w-full justify-center overflow-hidden">
          <div className="flex justify-center items-center p-2">
            <img
              className="object-contain h-28"
              src="../assets/bloomlogo.png"
              alt="Bitcare"
            />
          </div>
          <div className="flex flex-col gap-4 p-4">
            <div className="grid gap-2 ">
              <span className="text-3xl font-bold">Welcome!</span>
              <span className="text-sm font-medium">Log in your account</span>
            </div>
            <div className="grid gap-4">
              <form onSubmit={login} className="grid gap-6">
                <div className="grid">
                  <label
                    htmlFor="email"
                    className="font-bold text-xs uppercase tracking-wide"
                  >
                    Email
                  </label>
                  <Tooltip
                    title={errors.email}
                    open={Boolean(errors.email)}
                    placement="top-end"
                  >
                    <input
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={formvalues.email}
                      onChange={handleChange}
                      autoFocus={true}
                      className={`mt-1 block w-full  rounded-sm  shadow-sm  sm:text-sm
                      ${
                        errors.email
                          ? "border-red-500 focus:ring-0 focus:border-red-500"
                          : "border-gray-400 focus:border-primary-color focus:ring-primary-color focus:ring-0"
                      }
                    `}
                    />
                  </Tooltip>
                </div>

                <div className="grid">
                  <label
                    htmlFor="password"
                    className="font-bold text-xs uppercase tracking-wide"
                  >
                    Password
                  </label>
                  <Tooltip
                    title={errors.password}
                    open={Boolean(errors.password)}
                    placement="top-end"
                  >
                    <div className="relative mt-1 rounded-sm shadow-sm">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={formvalues.password}
                        onChange={handleChange}
                        placeholder="Password"
                        className={`mt-1 block w-full  rounded-sm  shadow-sm  sm:text-sm
                      ${
                        errors.password
                          ? "border-red-500 focus:ring-0 focus:border-red-500"
                          : "border-gray-400 focus:border-primary-color focus:ring-primary-color focus:ring-0"
                      }
                    `}
                      />
                      {formvalues.password && (
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 px-3 py-2 text-gray-600 hover:text-gray-800 focus:outline-none"
                          onClick={handleTogglePassword}
                        >
                          {showPassword ? (
                            <AiFillEye className="w-5 h-5" aria-hidden="true" />
                          ) : (
                            <AiFillEyeInvisible
                              className="w-5 h-5"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      )}
                    </div>
                  </Tooltip>
                </div>
                <div className="flex justify-end capitalize text-sm font-semibold">
                  <span
                    onClick={() => setIsForgot(true)}
                    className="hover:text-primary-color cursor-pointer"
                  >
                    forgot password ?
                  </span>
                </div>
                <button
                  type="submit"
                  className="bg-primary-color p-3 text-white font-semibold uppercase hover:shadow-xl"
                >
                  Login
                </button>
              </form>
              <div className="flex justify-center gap-1">
                <span>Don't have an account?</span>
                <span
                  onClick={() => setNewUser(true)}
                  className="cursor-pointer text-primary-color font-semibold hover:underline"
                >
                  Register
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;

/**
 * 
**Login Component Overview:
The Login component is used to provide a login form for users to sign in to their accounts. 
It handles user authentication using Firebase authentication and interacts with Redux to manage the user's information.

**Props:
setNewUser: A function to control the state in the parent component and trigger the registration form
 when the user wants to register as a new user.

**State:
* formvalues: A state to store the form values, including the email and password entered by the user.
* errors: A state to store error messages that might occur during the login process.
* isForgot: A state to manage whether the user has clicked on the "forgot password" link and wants to reset the password.
* showPassword: A state to toggle the visibility of the password input field.

**Functions:
handleTogglePassword: A function to toggle the visibility of the password input.
handleChange: A function to update the form values as the user types in the input fields.
validate: A function to handle and map Firebase authentication error codes to custom error messages.
login: A function to handle the login process when the user submits the login form. 
It uses Firebase's signInWithEmailAndPassword method to sign in the user with the provided email and password. 
If successful, it updates the Redux store with the user's email, sets a cookie to remember the user's login status,
 and navigates to the home page. If there's an error, it calls the validate function to display the appropriate error message.
 */
