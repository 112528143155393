import React, { useState } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import Iframe from "react-iframe";

const VagaroLocation = () => {
  const [iframeUrl, setIframeUrl] = useState(""); // State variable to hold the URL for iframe
  const [showButtons, setShowButtons] = useState(true); // State variable to track whether to show buttons or not

  const location = [
    {
      id: "id1",
      name: "Weight Management",
      value: "weight",
      address: "2100 Dallas Pkwy Unit 115, Plano, TX 75093",
      link: "https://weightloss.bloom24now.com/semaglutide-weight-loss",
    },
    {
      id: "id2",
      name: "Hormone Management",
      address: "8837 Lebanon Rd, Suite 200, Frisco, TX 75034",
      value: "hormone",
      link: "",
    },
    {
      id: "id3",
      name: "Sleep Management",
      address: "8837 Lebanon Rd, Suite 200, Frisco, TX 75034",
      value: "sleep",
      link: "",
    },
  ];

  // Function to handle button click and set iframe URL
  const handleButtonClick = (link) => {
    setIframeUrl(link);
    setShowButtons(false); // Hide buttons when a button is clicked
  };

  // Function to handle back button click and reset state to show buttons
  const handleBackButtonClick = () => {
    setShowButtons(true);
    setIframeUrl(""); // Clear iframe URL when going back
  };

  return (
    <div className="mt-20 md:mt-10 mx-3">
      <div className="md:bg-secondary-bg md:p-6 mt-4 rounded-sm md:mx-5 flex justify-center relative">
        {iframeUrl && (
          <button
            onClick={handleBackButtonClick}
            className="absolute top-2 left-2 bg-gray-300 text-gray-700 py-2 px-4 rounded-md"
          >
            <IoIosArrowBack size={24} />
          </button>
        )}
        <div className="w-full xl:w-[50%] grid gap-4">
          {/* Conditionally render buttons */}
          {showButtons && (
            <>
              {location.map((loc) => (
                <div key={loc.id} className="bg-white shadow p-4 rounded-sm">
                  {loc.link ? (
                    <button
                      onClick={() => handleButtonClick(loc.link)}
                      className="flex justify-between items-center rounded-sm w-full
                        bg-secondary-bg transition-all ease-in duration-300 hover:bg-button-bg
                        hover:text-white  text-gray-700  p-3 cursor-pointer"
                    >
                      <div className="flex flex-col gap-2 flex-1">
                        <span className="text-xl font-bold tracking-wider capitalize">
                          {loc.name}
                        </span>
                      </div>
                      <div>
                        <IoIosArrowForward size={24} />
                      </div>
                    </button>
                  ) : (
                    <div className="text-center">
                      <span className="text-xl font-bold tracking-wider capitalize">
                        {loc.name}
                      </span>
                      <p className="text-blue-500">Coming Soon</p>
                    </div>
                  )}
                </div>
              ))}
            </>
          )}

          {/* Conditionally render iframe */}
          {iframeUrl && (
            <Iframe
            url={iframeUrl}
            height="740px"
            className="w-full"
            id=""
            style={{ aspectRatio: "16/9" }}
            display="block"
            // position="relative"
          />
            // <Iframe
            //   url={iframeUrl}
            //   width="640px"
            //   height="520px"
            //   id=""
            //   className=""
            //   display="block"
            //   position="relative"
            // />
          )}
        </div>
      </div>
    </div>
  );
};

export default VagaroLocation;



// import React, { useState } from "react";
// import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
// import Iframe from "react-iframe";

// const VagaroLocation = () => {
//   const [iframeUrl, setIframeUrl] = useState(""); // State variable to hold the URL for iframe
//   const [showButtons, setShowButtons] = useState(true); // State variable to track whether to show buttons or not

//   const location = [
//     {
//       id: "id1",
//       name: "Weight Management",
//       value: "weight",
//       address: "2100 Dallas Pkwy Unit 115, Plano, TX 75093",
//       link: "https://weightloss.bloom24now.com/semaglutide-weight-loss",
//     },
//     {
//       id: "id2",
//       name: "Hormone Management",
//       address: "8837 Lebanon Rd, Suite 200, Frisco, TX 75034",
//       value: "hormone",
//       link: "",
//     },
//     {
//       id: "id3",
//       name: "Sleep Management",
//       address: "8837 Lebanon Rd, Suite 200, Frisco, TX 75034",
//       value: "sleep",
//       link: "",
//     },
//   ];

//   // Function to handle button click and set iframe URL
//   const handleButtonClick = (link) => {
//     setIframeUrl(link);
//     setShowButtons(false); // Hide buttons when a button is clicked
//   };

//   // Function to handle back button click and reset state to show buttons
//   const handleBackButtonClick = () => {
//     setShowButtons(true);
//     setIframeUrl(""); // Clear iframe URL when going back
//   };

//   return (
//     <div className="mt-20 md:mt-10 mx-3">
//       <div className="md:bg-secondary-bg md:p-6 mt-4 rounded-sm md:mx-5 flex justify-center relative">
//         {iframeUrl && (
//           <button
//             onClick={handleBackButtonClick}
//             className="absolute top-2 left-2 bg-gray-300 text-gray-700 py-2 px-4 rounded-md"
//           >
//             <IoIosArrowBack size={24} />
//           </button>
//         )}
//         <div className="w-full xl:w-[50%] grid gap-4">
//           {/* Conditionally render buttons */}
//           {showButtons && (
//             <>
//               {location.map((loc) => (
  
//                 <div key={loc.id} className="bg-white shadow p-4 rounded-sm">
                  
//                   <button
//                     onClick={() => handleButtonClick(loc.link)}
//                     className="flex justify-between items-center rounded-sm w-full
//                       bg-secondary-bg transition-all ease-in duration-300 hover:bg-button-bg
//                       hover:text-white  text-gray-700  p-3 cursor-pointer"
//                   >
//                     <div className="flex flex-col gap-2 flex-1">
//                       <span className="text-xl font-bold tracking-wider capitalize">
//                         {loc.name}
//                       </span>
//                     </div>
//                     <div>
//                       <IoIosArrowForward size={24} />
//                     </div>
//                   </button>
//                 </div>
//               ))}
//             </>
//           )}

//           {/* Conditionally render iframe */}
//           {iframeUrl && (
//             <Iframe
//               url={iframeUrl}
//               width="640px"
//               height="520px"
//               id=""
//               className=""
//               display="block"
//               position="relative"
//             />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VagaroLocation;


// import React, { useState } from "react";
// import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
// import Iframe from "react-iframe";

// const VagaroLocation = () => {
//   const [iframeUrl, setIframeUrl] = useState(""); // State variable to hold the URL for iframe
//   const [showButtons, setShowButtons] = useState(true); // State variable to track whether to show buttons or not
//   const [clickedIndex, setClickedIndex] = useState(-1); // State variable to hold the index of the clicked button

//   const location = [
//     {
//       id: "id1",
//       name: "Weight Management",
//       value: "weight",
//       address: "2100 Dallas Pkwy Unit 115, Plano, TX 75093",
//       link: "https://weightloss.bloom24now.com/semaglutide-weight-loss",
//     },
//     {
//       id: "id2",
//       name: "Hormone Management",
//       address: "8837 Lebanon Rd, Suite 200, Frisco, TX 75034",
//       value: "hormone",
//       link: "",
//     },
//     {
//       id: "id3",
//       name: "Sleep Management",
//       address: "8837 Lebanon Rd, Suite 200, Frisco, TX 75034",
//       value: "sleep",
//       link: "",
//     },
//   ];

//   // Function to handle button click and set iframe URL
//   const handleButtonClick = (link, index) => {
//     setIframeUrl(link);
//     setShowButtons(false); // Hide buttons when a button is clicked
//     setClickedIndex(index); // Set the index of the clicked button
//   };

//   // Function to handle back button click and reset state to show buttons
//   const handleBackButtonClick = () => {
//     setShowButtons(true);
//     setIframeUrl(""); // Clear iframe URL when going back
//     setClickedIndex(-1); // Reset clicked index
//   };

//   return (
//     <div className="mt-20 md:mt-10 mx-3">
//       <div className="md:bg-secondary-bg md:p-6 mt-4 rounded-sm md:mx-5 flex justify-center relative">
//         {showButtons && iframeUrl && (
//           <button
//             onClick={handleBackButtonClick}
//             className="absolute top-2 left-2 bg-gray-300 text-gray-700 py-2 px-4 rounded-md"
//           >
//             <IoIosArrowBack size={24} />
//           </button>
//         )}
//         <div className="w-full xl:w-[50%] grid gap-4">
//           {/* Render buttons */}
//           {location.map((loc, index) => (
//             <div key={loc.id} className="bg-white shadow p-4 rounded-sm">
//               {clickedIndex === index && !showButtons && loc.link === "" ? (
//                 // If button clicked and link is not available, show "Coming Soon" message
//                 <div className="text-center">
//                   <span className="text-xl font-bold tracking-wider capitalize">
//                     {loc.name}
//                   </span>
//                   <p>Coming Soon</p>
//                 </div>
//               ) : (
//                 // If button not clicked or link is available, render clickable button
//                 <button
//                   onClick={() => handleButtonClick(loc.link, index)}
//                   className="flex justify-between items-center rounded-sm w-full
//                     bg-secondary-bg transition-all ease-in duration-300 hover:bg-button-bg
//                     hover:text-white  text-gray-700  p-3 cursor-pointer"
//                 >
//                   <div className="flex flex-col gap-2 flex-1">
//                     <span className="text-xl font-bold tracking-wider capitalize">
//                       {loc.name}
//                     </span>
//                   </div>
//                   <div>
//                     <IoIosArrowForward size={24} />
//                   </div>
//                 </button>
//               )}
//             </div>
//           ))}

//           {/* Conditionally render iframe */}
//           {iframeUrl && (
//             <Iframe
//               url={iframeUrl}
//               width="640px"
//               height="520px"
//               id=""
//               className=""
//               display="block"
//               position="relative"
//             />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VagaroLocation;



// import React, { useState } from "react";
// import { IoIosArrowForward } from "react-icons/io";
// import Iframe from "react-iframe";

// const VagaroLocation = () => {
//   const [iframeUrl, setIframeUrl] = useState(""); // State variable to hold the URL for iframe

//   const location = [
//     {
//       id: "id1",
//       name: "Weight Management",
//       value: "weight",
//       address: "2100 Dallas Pkwy Unit 115, Plano, TX 75093",
//       link: "https://weightloss.bloom24now.com/semaglutide-weight-loss",
//     },
//     {
//       id: "id2",
//       name: "Hormone Management",
//       address: "8837 Lebanon Rd, Suite 200, Frisco, TX 75034",
//       value: "hormone",
//       link: "",
//     },
//     {
//       id: "id3",
//       name: "Sleep Management",
//       address: "8837 Lebanon Rd, Suite 200, Frisco, TX 75034",
//       value: "sleep",
//       link: "",
//     },
//   ];

//   // Function to handle button click and set iframe URL
//   const handleButtonClick = (link) => {
//     setIframeUrl(link);
//   };

//   return (
//     <div className="mt-20 md:mt-10 mx-3">
//       <div className="md:bg-secondary-bg md:p-6 mt-4 rounded-sm md:mx-5 flex justify-center">
//         <div className="w-full xl:w-[50%] grid gap-4">
//           {location.map((loc) => (
//             <div key={loc.id} className="bg-white shadow p-4 rounded-sm">
//               <button
//                 onClick={() => handleButtonClick(loc.link)}
//                 className="flex justify-between items-center rounded-sm w-full
//                   bg-secondary-bg transition-all ease-in duration-300 hover:bg-button-bg
//                   hover:text-white  text-gray-700  p-3 cursor-pointer"
//               >
//                 <div className="flex flex-col gap-2 flex-1">
//                   <span className="text-xl font-bold tracking-wider capitalize">
//                     {loc.name}
//                   </span>
//                 </div>
//                 <div>
//                   <IoIosArrowForward size={24} />
//                 </div>
//               </button>
//             </div>
//           ))}
//           {iframeUrl && (
//             <Iframe
//               url={iframeUrl}
//               width="640px"
//               height="520px"
//               id=""
//               className=""
//               display="block"
//               position="relative"
//             />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VagaroLocation;











// import React from "react";
// import { IoIosArrowForward } from "react-icons/io";
// import Iframe from "react-iframe";

// const VagaroLocation = () => {
//   const location = [
//     {
//       id: "id1",
//       name: "Weight Management",
//       value: "weight",
//       address: "2100 Dallas Pkwy Unit 115, Plano, TX 75093",
//       link: "https://weightloss.bloom24now.com/semaglutide-weight-loss",
//     },
//     {
//       id: "id2",
//       name: "Hormone Management",
//       address: "8837 Lebanon Rd, Suite 200, Frisco, TX 75034",
//       value: "hormone",
//       link: "",
//     },
//     {
//       id: "id3",
//       name: "Sleep Management",
//       address: "8837 Lebanon Rd, Suite 200, Frisco, TX 75034",
//       value: "sleep",
//       link: "",
//     },
//   ];

//   return (
//     <div className="mt-20 md:mt-10 mx-3">
//       <div className="md:bg-secondary-bg md:p-6 mt-4 rounded-sm md:mx-5 flex justify-center">
//         <div className="w-full xl:w-[50%] grid gap-4">
//           {location.map((location) => (
//             <div key={location.id} className="bg-white shadow p-4 rounded-sm">
//               <a
//                 href={location.link}
//                 // target="_blank"
//                 rel="noreferrer"
//                 className="flex justify-between items-center rounded-sm
//           bg-secondary-bg transition-all ease-in duration-300 hover:bg-button-bg
//            hover:text-white  text-gray-700  p-3 cursor-pointer"
//               >
//                 <div className="flex flex-col gap-2 flex-1">
//                   <span className="text-xl font-bold tracking-wider capitalize">
//                     {location.name}
//                   </span>
//                   {/* <span className="text-base font-semibold md:text-base md:font-medium whitespace-pre-wrap">
//                     {location.address}
//                   </span> */}
//                 </div>
//                 <div>
//                   <IoIosArrowForward size={24} />
//                 </div>
//               </a>
//             </div>
//           ))}
//           <Iframe
//             url="https://weightloss.bloom24now.com/semaglutide-weight-loss"
//             width="640px"
//             height="320px"
//             id=""
//             className=""
//             display="block"
//             position="relative"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VagaroLocation;

/**
 * 
 ** Documentation: VagaroLocation Component

The VagaroLocation component represents a page that displays information about different locations along with links 
to book appointments using the Vagaro booking service.
 It renders a list of locations with their names, addresses, and booking links. 
 When a location is clicked, the user is redirected to the respective Vagaro booking page.

*?Dependencies:
* react: The core library for building user interfaces in React applications.
* react-router-dom: A library that provides routing functionality for React applications.
* react-icons/io: A library that provides access to Icon components for various popular icon sets.


**Hooks:
* useNavigate: A hook from react-router-dom that returns a function to navigate to different routes programmatically.

*?Component Structure:
The component is a functional component that uses the useNavigate hook for navigation.
The component maps through the location array and renders a list of locations using the information from the array.

*?Locations Data:
The component uses the location array to store information about different locations. 
Each location object contains the following properties:

* id: A unique identifier for the location.
* name: The name of the location.
* address: The address of the location.
* value: A value associated with the location (e.g., "plano" or "frisco").
* link: The booking link for the location.

**Booking Link Handling:
The handleClick function is used to navigate to the respective booking page based on the value associated 
with the clicked location. If the value is "plano", it navigates to "/vagaroplano", and if the value is "frisco", 
it navigates to "/vagarofrisco".

**Rendered Output:
The component renders a list of location cards. Each card displays the location's name and address. 
The cards are clickable and use a link to open the Vagaro booking page in a new tab when clicked. 
The arrow icon (IoIosArrowForward) is displayed at the end of each card to indicate that it is clickable.
 * 
 * 
 */
