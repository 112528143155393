import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { db } from "../../firebase";
import { updateDoc, doc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { user, userId } from "../../redux/reducers/userReducer";

const Editprofile = () => {
  const currentuser = useSelector(user);
  const currentuserId = useSelector(userId);
  const [fullname, setFullname] = useState(currentuser.name);
  const [phone, setPhone] = useState(currentuser.phone);
  const [city, setCity] = useState(currentuser.city);
  const [address, setAddress] = useState(currentuser.address);
  const [maritialstatus, setMaritialstatus] = useState(
    currentuser.maritialstatus
  );
  const [state, setState] = useState(currentuser.state);
  const [postal, setPostal] = useState(currentuser.zipcode);
  const [nameerror, setNameerror] = useState("");
  const [phoneerror, setPhoneError] = useState("");
  const navigate = useNavigate();

  // Function to validate name
  const validateFullname = () => {
    let error = "";
    if (!fullname.trim()) {
      error = "Full name is required";
    } else if (fullname.trim().length < 4) {
      error = "Full name must be atleast 4 characters";
    } else if (fullname.trim().length > 30) {
      error = "Full name must not exceed 30 characters";
    }

    setNameerror(error);
    return error === "";
  };

  //Function to change the format of the phone number
  const handlePhone = (event) => {
    const { name, value } = event.target;
    let formattedValue = value;
    if (name === "phone") {
      // remove all non-numeric characters from the input
      formattedValue = value.replace(/\D/g, "");

      // add hyphen after third and sixth digits
      if (formattedValue.length > 3 && formattedValue.length < 7) {
        formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(
          3
        )}`;
      } else if (formattedValue.length >= 7) {
        formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(
          3,
          6
        )}-${formattedValue.slice(6, 10)}`;
      }
    }

    setPhone(formattedValue);
  };

  //Function to validate phone number
  const validatePhone = () => {
    let error = "";
    const pattern = /^(\([0-9]{3}\)\s*|[0-9]{3}-)\s*[0-9]{3}-\s*[0-9]{4}$/;
    const isValid = pattern.test(phone);
    if (!phone.trim()) {
      error = "Phone number is required";
    } else if (!isValid) {
      error = "Please enter a valid US phone number";
    }
    setPhoneError(error);
    return error === "";
  };

  // Function to update the changes into the firebase database
  const handleSubmit = async (e) => {
    e.preventDefault();

    const isFullnameValid = validateFullname();
    const isPhoneValid = validatePhone();

    if (isFullnameValid && isPhoneValid) {
      const updateUserInfo = doc(
        db,
        "users/userProfiles/AllUsers",
        currentuserId
      );
      await updateDoc(updateUserInfo, {
        address: address,
        city: city,
        name: fullname,
        phone: phone,
        maritialstatus: maritialstatus,
        state: state,
        zipcode: postal,
      })
        .then(() => {
          navigate("/profile");
        })
        .catch((Err) => Err.message);
    }
  };
  return (
    <div className="mx-3 mt-20 md:mt-10">
      <Header title="Edit Profile" Button={true} />

      {/* form section */}
      <div className="md:bg-secondary-bg md:p-6 mt-4  rounded-sm mb-3 flex justify-center">
        <div className="w-full md:flex md:justify-center">
          <form onSubmit={handleSubmit}>
            <div className="overflow-hidden shadow">
              <div className="bg-white  px-4 py-5 sm:p-6 rounded-sm">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 ">
                    <label className="block text-sm font-medium text-primary-color">
                      Full name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={fullname}
                      onChange={(e) => setFullname(e.target.value)}
                      onBlur={validateFullname}
                      className="mt-1 block w-full rounded-sm border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm focus:ring-0"
                    />
                    {nameerror && (
                      <p className="text-xs font-bold text-red-600 mt-1">
                        {nameerror}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6 ">
                    <label className="block text-sm font-medium text-primary-color">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      value={phone}
                      onChange={handlePhone}
                      onBlur={validatePhone}
                      className="mt-1 block w-full rounded-sm border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                    />
                    {phoneerror && (
                      <p className="text-xs font-bold text-red-600 mt-1">
                        {phoneerror}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6 ">
                    <label className="block text-sm font-medium text-primary-color">
                      Maritial Status
                    </label>
                    <select
                      name="maritialstatus"
                      value={maritialstatus}
                      onChange={(e) => setMaritialstatus(e.target.value)}
                      className="mt-1 block w-full rounded-sm border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-primary-color focus:outline-none focus:ring-primary-color sm:text-sm"
                    >
                      <option value="">Select</option>
                      <option>Single</option>
                      <option>Married</option>
                      <option>Divorced</option>
                      <option>Legally Separated</option>
                      <option>Widowed</option>
                    </select>
                  </div>

                  <div className="col-span-6">
                    <label className="block text-sm font-medium text-primary-color">
                      Street address
                    </label>
                    <input
                      type="text"
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="mt-1 block w-full rounded-sm border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label className="block text-sm font-medium text-primary-color">
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      className="mt-1 block w-full rounded-sm  border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label className="block text-sm font-medium text-primary-color">
                      State / Province
                    </label>
                    <input
                      type="text"
                      name="region"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      className="mt-1 block w-full rounded-sm border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="postal-code"
                      className="block text-sm font-medium text-primary-color"
                    >
                      ZIP / Postal code
                    </label>
                    <input
                      type="text"
                      name="postalcode"
                      value={postal}
                      onChange={(e) => setPostal(e.target.value)}
                      className="mt-1 block w-full rounded-sm border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex items-center justify-center">
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-sm border border-transparent bg-primary-color py-2 px-4 text-sm font-medium text-white shadow-sm hover:primary-color focus:outline-none "
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Editprofile;

/**

**Documentation: Editprofile Component
The Editprofile component represents the page where users can edit their profile information. 
It allows users to update their full name, phone number, marital status, address, city, state/province, and postal code.
 The component includes form fields to collect this information and handles validation for the user inputs.

**Dependencies:
react: The core library for building user interfaces in React applications.
react-router-dom: A library that provides routing functionality for React applications.
redux: A state management library for React applications.
react-redux: The official React bindings for Redux.
firebase: A backend-as-a-service platform that allows developers to build web and mobile applications without managing servers.

**State Variables:
currentuser: Holds the user's profile information obtained from the Redux store.
currentuserId: Holds the user ID obtained from the Redux store.
fullname: Represents the current value of the user's full name.
phone: Represents the current value of the user's phone number.
city: Represents the current value of the user's city.
address: Represents the current value of the user's street address.
maritialstatus: Represents the current value of the user's marital status.
state: Represents the current value of the user's state/province.
postal: Represents the current value of the user's postal code.
nameerror: Holds an error message related to the full name input field (if any).
phoneerror: Holds an error message related to the phone number input field (if any).

**Hooks:
useSelector: A hook from react-redux that allows accessing data from the Redux store.
useState: A hook that allows adding state to functional components.
useNavigate: A hook from react-router-dom that provides a function to navigate to different routes.

**Component Structure:
The component is a functional component that uses the useSelector hook to access the currentuser and currentuserId 
data from the Redux store.
The component includes state variables for managing the user inputs and error messages for the form fields.
The component includes functions for validating the full name and phone number inputs and formatting the phone number.
The component includes a form section with input fields for full name, phone number, marital status, address, city, s
tate/province, and postal code. The user can edit and update these fields.
The component also displays error messages below the input fields if the user input is invalid.
When the user clicks the "Save" button, the component validates the user inputs. If all inputs are valid, 
it updates the user's profile information in the Firebase database and navigates the user back to the profile page.

**Rendered Output:
The component renders a form with input fields for the user to edit their profile information, 
including full name, phone number, marital status, address, city, state/province, and postal code. 
The user can update these fields and click the "Save" button to save the changes.

 */
