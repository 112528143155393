import React from "react";
import { AiOutlineUser, AiOutlineMail } from "react-icons/ai";
import defaultlogo from "../../data/default-profile.png";
import malelogo from "../../data/default-male.jpeg";
import femalelogo from "../../data/default-female.jpeg";
import { BsGenderAmbiguous, BsCalendar2Week } from "react-icons/bs";
import { FiSmartphone } from "react-icons/fi";
import { useStateContext } from "../../contexts/ContextProvider";

const ProfileCard = ({ name, phone, email, gender, dob, age, image }) => {
  const { activeMenu } = useStateContext();

  return (
    <div
      className={`w-full xl:w-auto h-auto rounded-sm  mb-5 px-6 py-3  shadow bg-white ${
        activeMenu ? "lg:w-full md:w-full " : "md:w-full "
      }`}
    >
      <div className="flex flex-col  gap-3 ">
        <div className="flex flex-col items-center justify-center mt-2">
          {image ? (
            <img
              className="w-[150px] h-[150px] object-cover rounded-full "
              src={image}
              alt="default_logo"
            />
          ) : (
            <img
              className="w-[150px] h-[150px] object-cover rounded-full "
              src={
                gender
                  ? gender === "Male"
                    ? malelogo
                    : femalelogo
                  : defaultlogo
              }
              alt="default_logo"
            />
          )}
          <span className="text-2xl mt-3 font-semibold text-black capitalize">
            {name}
          </span>
        </div>
        <span className="h-[1px] w-full bg-gray-200 lg:w-full"></span>
        <div className="mt-4 text-gray-700 text-md flex flex-col gap-4 px-4">
          <div className="grid gap-2 grid-cols-2">
            <div className="flex items-center gap-2">
              <span className="font-semibold">
                <AiOutlineMail />
              </span>
              <span className="font-semibold">Email :</span>
            </div>
            <span className="text-black font-medium truncate">
              {email}
            </span>
          </div>
          <div className="grid gap-2 grid-cols-2">
            <div className="flex items-center gap-2">
              <span className="font-semibold">
                <FiSmartphone />
              </span>
              <span className="font-semibold">Phone Number :</span>
            </div>
            <span className="text-black font-medium truncate">
              {phone}
            </span>
          </div>
          <div className="grid gap-2 grid-cols-2">
            <div className="flex items-center gap-2">
              <span className="font-semibold">
                <BsGenderAmbiguous />
              </span>
              <span className="font-semibold">Gender :</span>
            </div>
            <span className="text-black font-medium capitalize truncate">
              {gender}
            </span>
          </div>
          <div className="grid gap-2 grid-cols-2">
            <div className="flex items-center gap-2">
              <span className="font-semibold">
                <BsCalendar2Week />
              </span>
              <span className="font-semibold">Date of birth :</span>
            </div>
            <span className="text-black font-medium truncate">
              {dob}
            </span>
          </div>
          <div className="grid gap-2 grid-cols-2">
            <div className="flex items-center gap-2">
              <span className="font-semibold">
                <AiOutlineUser />
              </span>
              <span className="font-semibold">Age :</span>
            </div>
            <span className="text-black font-medium truncate">
              {age}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;

/**
 * The ProfileCard component is designed to display user profile information, including 
 * the user's name, email, phone number, gender, date of birth, age, and profile image. 
 * It is a reusable component that can be used to create user profile cards within your React application.

**Props:
The ProfileCard component accepts the following props:

1. name (string, required): The name of the user to be displayed in the profile card.
2. phone (string, required): The phone number of the user to be displayed in the profile card.
3. email (string, required): The email address of the user to be displayed in the profile card.
4. gender (string, optional): The gender of the user to be displayed in the profile card. It can be "Male", "Female", or left empty if not specified. 
  If no gender is provided, a default gender-specific avatar will be displayed.
5. dob (string, required): The date of birth of the user to be displayed in the profile card in the format "YYYY-MM-DD".
6. age (number, required): The age of the user to be displayed in the profile card.
7. image (string, optional): The URL of the profile image of the user to be displayed in the profile card.
   If not provided, a default avatar image based on the gender (if provided) will be shown.
 */
