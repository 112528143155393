import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Sidebar, Navbar } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import {
  Profile,
  MedicalRecord,
  Editprofile,
  Changepassword,
  Dashboard,
  Membership,
  Rewards,
  Singlemembership,
  MedicalHistory,
  PreandPostInstructions,
  BmiCalculator,
  VagaroLocation,
  Packages,
  PackageCard,
  Alltreatments,
  Medspaservice,
  Referral,
  VirtualConsultation,
  FamilyMember,
  AddMember,
  UploadPicture,
  FAQSection,
} from ".";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { user, userId } from "../redux/reducers/userReducer";
import { db, messaging } from "../firebase";
import {
  collection,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  addDoc,
} from "firebase/firestore";
import { getToken } from "firebase/messaging";

const Home = () => {
  const { activeMenu } = useStateContext();
  const currentUserID = useSelector(userId);
  const currentUser = useSelector(user);

  const navigate = useNavigate();

  useEffect(() => {
    const askNotificationPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const registrationToken = await getToken(messaging, {
          vapidKey:
            "BHcNm6IQ4iWnQXFg0Hux3VZuROZ4yvfK_NvGHBBF_dlh8ys_S2jLU-ldK8cGDSjDWDDB08LUp5j0oAE-ROnQQNE",
        });
        // console.log("Registration token:", registrationToken);

        const docRef = doc(db, "Bitcare_Notifications", currentUserID);
        await setDoc(
          docRef,
          {
            userID: currentUserID,
            notificationtoken: registrationToken,
            userName: currentUser.name,
          },
          {
            merge: true,
          }
        );
      } else if (permission === "denied") {
        console.log("You denied for the notification");
      }
    };

    return () => {
      askNotificationPermission();
    };
  }, []);

  useEffect(() => {
    const addNotificationtoDatabase = async () => {
      navigator.serviceWorker.addEventListener("message", async (event) => {
        const title = event.data.notification.title;
        const body = event.data.notification.body;
        // setTitle(title);
        // setBody(body);
        try {
          const userDocRef = doc(
            collection(db, "Bitcare_Notifications"),
            currentUserID
          );

          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            // If the document exists, create a subcollection named "messages"
            const messagesCollectionRef = collection(userDocRef, "messages");
            // const messageDocRef = doc(messagesCollectionRef);
            await addDoc(messagesCollectionRef, {
              title: title,
              body: body,
              timestamp: serverTimestamp(),
            });
            console.log("Message document created");
          } else {
            console.log("User document not found for ID");
          }
        } catch (err) {
          console.log("Error while creating document", err);
        }
      });
    };
    return () => {
      addNotificationtoDatabase();
    };
  }, []);

  useEffect(() => {
    let loggedIn = Cookies.get("loggedIn");
    if (!loggedIn) {
      navigate("/auth");
    }
  }, []);

  return (
    <>
      <div className="flex relative">
        {/* sidebar */}
        {activeMenu ? (
          <div
            className="w-72
                    fixed
                   sidebar 
                    bg-white"
          >
            {/* */}
            <Sidebar />
          </div>
        ) : (
          <div className="w-0">
            <Sidebar />
          </div>
        )}

        {/* navbar */}
        <div
          className={
            activeMenu
              ? " bg-main-bg min-h-screen md:ml-72 w-full"
              : " bg-main-bg min-h-screen w-full flex-2"
          }
        >
          <div
            className="fixed md:static
                    md:bg-main-bg
                    bg-white
                    navbar
                    w-full           
                "
          >
            <Navbar />
          </div>

          {/* Routes */}
          <div>
            <Routes>
              {/* -----------------------------DASHBOARD ROUTE--------------------------------------------- */}
              <Route path="/" element={<Dashboard />} />
              {/* -----------------------------CHANGE PASSWORD ROUTE--------------------------------------- */}
              <Route path="/changepassword" element={<Changepassword />} />
              {/* -----------------------------Family Member ROUTE--------------------------------------- */}
              <Route path="/familymember" element={<FamilyMember />} />
              {/* ------------------------------PROFILE PAGE ROUTE----------------------------------------- */}
              <Route path="/addmember" element={<AddMember />} />
              {/* ------------------------------PROFILE PAGE ROUTE----------------------------------------- */}
              <Route path="/profile" element={<Profile />} />
              {/* ------------------------------EDIT PROFILE ROUTE----------------------------------------- */}
              <Route path="/editprofile" element={<Editprofile />} />
              {/* ------------------------------BOOKING PAGE ROUTE----------------------------------------- */}
              <Route path="/booking" element={<VagaroLocation />} />
               {/* ------------------------------VIRTUAL CONSULTATION PAGE ROUTE----------------------------------------- */}
               <Route path="/virtualconsultation" element={<VirtualConsultation />} />
              {/* ------------------------------MEDICAL RECORD ROUTE--------------------------------------- */}
              <Route path="/healthhistory" element={<MedicalRecord />} />
              {/* ------------------------------MEDICAL HISTORY ROUTE-------------------------------------- */}
              <Route path="/record" element={<MedicalHistory />} />
              {/* ------------------------------ALL MEMBERSHIP PAGE ROUTE---------------------------------- */}
              <Route path="/membership" element={<Membership />} />
              {/* ------------------------------SINGLE MEMBERSHIP PAGE ROUTE------------------------------- */}
              <Route path="/singlemembership" element={<Singlemembership />} />
              {/* ------------------------------ALL SERVICES PAGE ROUTE------------------------------------ */}
              <Route path="/medspaservices" element={<Alltreatments />} />
              {/* ------------------------------SINGLE SERVICE PAGE ROUTE---------------------------------- */}
              <Route path="/medspaservice" element={<Medspaservice />} />
              {/* ------------------------------ALL PACKAGES PAGE ROUTE------------------------------------ */}
              <Route path="/packages" element={<Packages />} />
              {/* ------------------------------SINGLE PACKAGE ROUTE--------------------------------------- */}
              <Route path="/package" element={<PackageCard />} />
              {/* ------------------------------REFERRAL PAGE ROUTE---------------------------------------- */}
              <Route path="/referral" element={<Referral />} />
              {/* ------------------------------REWARDS PAGE ROUTE----------------------------------------- */}
              <Route path="/rewards" element={<Rewards />} />
              {/* ------------------------------POST AND PRE INSTRUCTIONS ROUTE --------------------------- */}
              <Route path="/education" element={<PreandPostInstructions />} />
              {/* ------------------------------BMI CALCULATOR ROUTE--------------------------------------- */}
              <Route path="/bmicalculator" element={<BmiCalculator />} />
              {/* ------------------------------UPLOAD PICTURE ROUTE--------------------------------------- */}
              <Route path="/UploadPicture" element={<UploadPicture />} />
              {/* ------------------------------FAQ ROUTE--------------------------------------- */}
              <Route path="/FAQSection" element={<FAQSection />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

/**
 
**Documentation: Home Component

The Home component represents the main layout of the application and is responsible for rendering various pages/routes 
based on the user's navigation. It provides a sidebar for navigation, a top navbar, and renders different components based 
on the current route using React Router's Routes and Route components.

*?Dependencies:
* react: The core library for building user interfaces in React applications.
* react-router-dom: A library that provides routing functionality for React applications.
* react-redux: A library that enables React components to interact with the Redux store.
* firebase: The Firebase SDK used for interacting with Firebase services.

*?State Variables:
* activeMenu: Represents the state of the sidebar menu, used to control whether the sidebar is active or not.

*?Redux State:
* currentUserID: Holds the ID of the current user from the Redux store.
* currentUser: Holds the user data from the Redux store.

*?Hooks and Effects:

* useNavigate: A hook from react-router-dom that returns a function to navigate to different routes programmatically.
* useSelector: A hook from react-redux used to access data from the Redux store.
* useEffect: A React hook used for performing side effects in the component. It runs after rendering and can be used for various tasks like fetching data, handling subscriptions, etc.

*?Component Structure:

 The component wraps the main content inside a div element and uses the activeMenu state to conditionally apply 
 styles for the sidebar.
The sidebar and top navbar are rendered at the top level of the component.
The Routes component from react-router-dom is used to define different routes and their corresponding components.
Each Route maps a specific URL path to a corresponding component to be rendered when the URL matches.

**Routes and Components:
The Home component contains several nested Route components that map specific URLs to their corresponding components, such as:

/: Renders the Dashboard component.
/changepassword: Renders the Changepassword component.
/profile: Renders the Profile component.
/editprofile: Renders the Editprofile component.
/booking: Renders the VagaroLocation component.
/healthhistory: Renders the MedicalRecord component.
/record: Renders the MedicalHistory component.
/membership: Renders the Membership component.
/singlemembership: Renders the Singlemembership component.
/medspaservices: Renders the Alltreatments component.
/medspaservice: Renders the Medspaservice component.
/packages: Renders the Packages component.
/package: Renders the PackageCard component.
/referral: Renders the Referral component.
/rewards: Renders the Rewards component.
/education: Renders the PreandPostInstructions component.
/bmicalculator: Renders the BmiCalculator component.
Firebase Cloud Messaging (FCM) Integration:

**The component uses Firebase Cloud Messaging (FCM) to handle push notifications.
It requests permission from the user for notifications and sends the registration token to the database for the current user.
When a notification is received, it is added to the user's document in the "messages" subcollection.

*?Authentication and Navigation:
The component checks if the user is logged in by reading a "loggedIn" cookie. If not logged in, it redirects to the authentication page using the navigate function from react-router-dom.
 */
